import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'map',
    path: '/map',
    component: () => import('../pages/MapPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_MAP
    }
  }
];

export default routes;
