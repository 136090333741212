export interface ParcelModuleConfig {
  listType: ParcelListType;
  needParcelGroups: boolean;
  needGeoLayerUpload: boolean;
  needParcelLayerEditButton: boolean;
  needParcelSubLayerButton: boolean;
  layerListPosition: LayerListPosition;
}

export enum ParcelListType {
  FODLER = 'folder',
  TABLE = 'table',
  CARDS = 'cards'
}

export enum LayerListPosition {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top'
}
