import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'geoLayersUpload',
    path: '/geo-layers-upload',
    component: () => import('../pages/GeoLayersUploadPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_MAP
    }
  }
];

export default routes;
