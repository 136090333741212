import { ApiModule, type Crop } from '@common/index';
import type { ExpertAdvice, ExpertAdviceItem } from '../models';
import type { FeatureCollection, Polygon } from 'geojson';

export class ExpertAdviceApi extends ApiModule {
  protected endpoint = '/maximap/advice';

  public getExpertAdviceList(filters: any) {
    return this.api.get<ExpertAdviceItem[]>(`${this.endpoint}`, { params: { ...filters } });
  }

  public getExpertAdvice(id: string) {
    return this.api.get<FeatureCollection<Polygon, ExpertAdvice>>(`${this.endpoint}/${id}`);
  }

  public loadCrop() {
    return this.api.get<Crop[]>(`/proplanta/plant`);
  }
}
