<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  width: {
    type: String,
    default: '4rem'
  },
  color: {
    type: String,
    default: 'rgba(0, 0, 0, 0.7)'
  },
  opacity: {
    type: Number,
    default: 0.5
  }
});

const style = computed(() => ({ width: props.width, fill: props.color, opacity: props.opacity }));
</script>

<template>
  <svg
    viewBox="0 0 512 512"
    stroke="1px"
    :style="style">
    <path
      d="m440.823 436.213c4.143 0 7.5-3.357 7.5-7.5v-262.044c0-5.639-1.003-11.174-2.981-16.45l-36.609-97.663v-34.61c0-9.895-8.051-17.946-17.947-17.946h-269.572c-9.896 0-17.946 8.051-17.946 17.946v34.609l-36.609 97.664c-1.979 5.278-2.982 10.813-2.982 16.45v314.629c0 16.93 13.773 30.702 30.703 30.702h323.242c16.93 0 30.702-13.772 30.702-30.702v-17.511c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v17.511c0 8.658-7.044 15.702-15.702 15.702h-323.243c-8.658 0-15.703-7.044-15.703-15.702v-307.129h354.647v254.544c0 4.142 3.358 7.5 7.5 7.5zm-324.857-374.798h26.283c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-23.981v-28.469c0-1.625 1.321-2.946 2.946-2.946h269.573c1.625 0 2.946 1.321 2.946 2.946v28.469h-216.41c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h218.711l35.262 94.069c.453 1.209.825 2.439 1.126 3.685h-352.845c.302-1.245.674-2.475 1.127-3.685z"></path>
    <path
      d="m139.707 337.457c0 64.216 52.271 116.293 116.293 116.293 64.106 0 116.293-52.163 116.293-116.293 0-64.125-52.169-116.294-116.293-116.294s-116.293 52.169-116.293 116.294zm59.395 83.76c36.344-14.223 77.402-14.244 113.797 0-34.193 23.301-79.352 23.473-113.797 0zm25.676-94.491c-7.228-2.605-13.221-9.195-17.883-19.641 5.226.002 11.618-.422 19.104-1.332 13.312-1.623 20.393 3.779 20.374 18.892-4.483 2.104-13.161 5.118-21.595 2.081zm132.515 10.731c0 28.669-11.974 54.592-31.181 73.037-20.55-9.225-42.376-14.215-65.021-14.865v-11.945c10.609 2.361 21.404 1.754 30.911-3.06 14.045-7.113 23.267-22.139 27.408-44.658 1.231-6.69-5.473-12.102-11.748-9.458-2.161.912-8.737 3.689-26.109 2.679-7.879-.462-14.86 1.42-20.462 5.454v-4.942c2.812-23.759-10.512-42.046-36.904-38.835-17.317 2.108-23.284.952-24.711.545-6.475-1.842-12.439 4.276-10.421 10.723v.001c6.503 20.776 16.867 33.818 30.803 38.765 8.602 3.054 17.662 2.615 26.234-.081v55.018c-20.925 1.176-41.114 6.093-60.203 14.661-19.207-18.445-31.182-44.368-31.182-73.037 0-55.854 45.44-101.294 101.293-101.294s101.293 45.439 101.293 101.292zm-96.144 30.752c.407-6.081 2.079-15.034 7.08-20.018 3.033-3.022 7.109-4.339 12.455-4.03 9.369.543 16.297.123 21.523-.703-3.636 11.885-9.296 19.851-16.891 23.737-8.787 4.499-18.668 2.676-24.167 1.014z"></path>
  </svg>
</template>
