import { FormValidator, type FormValidatorConfig, type FormValidatorSettings, isEmpty, type FormValidatorContext } from '@common/index';
import { useApi } from '../../../services';
import { setApiValidationErrorMessages } from '@common/lib/modules/form/utils/validartor.utils';

export type PesticideNameValidatorSettings = FormValidatorSettings;

export type PesticideNameValidatorConfig = FormValidatorConfig<PesticideNameValidatorSettings>;

export class PesticideNameValidator extends FormValidator<PesticideNameValidatorSettings> {
  validatorApiService = useApi().pesticide;
  constructor() {
    super();
    this._name = 'pesticideName';
    this._settings = {
      invalidMessage: 'pesticide.validator.name.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value) || !context.element.enabled) {
      return;
    }

    try {
      await this.validatorApiService.validatePesticideName(value);
      this.valid = true;
    } catch (error: any) {
      this.valid = false;
      setApiValidationErrorMessages(error.data.data, this);
    }
  }
}
