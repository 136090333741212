<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  width: {
    type: String,
    default: '4rem'
  },
  color: {
    type: String,
    default: 'rgba(0, 0, 0, 0.7)'
  },
  opacity: {
    type: Number,
    default: 0.5
  }
});

const style = computed(() => ({ width: props.width, fill: props.color, opacity: props.opacity }));
</script>

<template>
  <svg
    viewBox="0 0 314 314"
    stroke="1px"
    :style="style">
    <path
      d="m157 314c86.570312 0 157-70.429688 157-157s-70.429688-157-157-157-157 70.429688-157 157 70.429688 157 157 157zm0-300c78.851562 0 143 64.148438 143 143s-64.148438 143-143 143-143-64.148438-143-143 64.148438-143 143-143zm0 0"></path>
    <path
      d="m118.300781 142.890625c16.011719 0 28.988281-12.980469 28.988281-28.988281.003907-16.011719-12.976562-28.992188-28.988281-28.992188s-28.988281 12.980469-28.988281 28.988282c.015625 16.003906 12.984375 28.972656 28.988281 28.992187zm0-43.980469c8.277344 0 14.988281 6.710938 14.988281 14.988282.003907 8.28125-6.707031 14.992187-14.988281 14.992187-8.277343 0-14.988281-6.710937-14.988281-14.992187.007812-8.273438 6.714844-14.976563 14.988281-14.988282zm0 0"></path>
    <path
      d="m164.953125 201.859375c0 16.011719 12.980469 28.988281 28.988281 28.988281 16.011719 0 28.992188-12.976562 28.992188-28.988281s-12.980469-28.992187-28.992188-28.992187c-16.003906.019531-28.96875 12.988281-28.988281 28.992187zm43.980469 0c0 8.277344-6.710938 14.988281-14.992188 14.988281-8.277344 0-14.988281-6.710937-14.988281-14.988281s6.710937-14.988281 14.988281-14.992187c8.277344.011718 14.980469 6.714843 14.992188 14.992187zm0 0"></path>
    <path
      d="m103.699219 238.726562c3.238281 2.113282 7.578125 1.199219 9.6875-2.039062l98.953125-151.726562c1.371094-2.097657 1.519531-4.765626.390625-7-1.132813-2.234376-3.371094-3.695313-5.871094-3.828126-2.5-.132812-4.882813 1.078126-6.246094 3.179688l-98.953125 151.726562c-2.113281 3.242188-1.199218 7.578126 2.039063 9.6875zm0 0"></path>
  </svg>
</template>
