import { ApiModule, type PaginatedApiResponse } from '@common/index';
import type { PesticideItem } from '../models';

export class PesticideApi extends ApiModule {
  protected endpoint = '/maximap/pesticide';

  public async listPesticides(params: any): Promise<PaginatedApiResponse<PesticideItem>> {
    const response = await this.api.get<PesticideItem[]>(`${this.endpoint}`, {
      params: { ...params }
    });

    const content = response.data;

    return {
      ...response,
      data: {
        items: content,
        page: 1,
        totalItems: content.length
      }
    };
  }

  public savePesticide(params: any) {
    return this.api.post(`${this.endpoint}`, {
      ...params
    });
  }

  public deletePesticide(id: string) {
    return this.api.delete(`${this.endpoint}/${id}`);
  }

  public validatePesticideName(name: string) {
    return this.api.get<{ valid: boolean }>(`/maximap/validate-pesticide-name`, { params: { name } });
  }
}
