import { defineStore } from 'pinia';
import type { ModuleConfig, ModuleConfigPartial } from '../models';
import { ref, type Ref } from 'vue';
import { createDefaultModuleConfig } from '../utils';
import { customDeepClone } from '@common/index';
import merge from 'lodash/merge';

export const useModulesConfigStore = defineStore('modulesConfigStore', () => {
  const moduleConfig: Ref<ModuleConfig> = ref(createDefaultModuleConfig());

  const setModuleConfig = (partialModuleConfig: ModuleConfigPartial) => {
    const defaultConfig = customDeepClone(createDefaultModuleConfig());

    const mergedConfig = merge(defaultConfig, partialModuleConfig) as ModuleConfig;

    if (partialModuleConfig.plan?.planTypeOptions) {
      mergedConfig.plan.planTypeOptions = partialModuleConfig.plan.planTypeOptions;
    }

    if (partialModuleConfig.plan?.doseTypeOptions) {
      mergedConfig.plan.doseTypeOptions = partialModuleConfig.plan.doseTypeOptions;
    }

    if (partialModuleConfig.plan?.availableDownloadFormats) {
      mergedConfig.plan.availableDownloadFormats = partialModuleConfig.plan.availableDownloadFormats;
    }

    moduleConfig.value = mergedConfig;
  };

  return {
    moduleConfig,
    setModuleConfig
  };
});
