export default {
  menu: {
    title: {
      tableDatabase: 'Tábla adatbázis',
      map: 'Térkép',
      fertilizerDatabase: 'Műtrágya-adatbázis',
      substanceDatabases: 'Anyagok',
      applicationPlans: 'Kijuttatási tervek',
      monitoring: 'Monitoring',
      settings: 'Beállítások',
      admin: 'Admin felület',
      home: 'Főoldal',
      advices: 'Szaktanácsadások'
    },
    description: {
      tableDatabase: 'Adatok kezelése, rendszerezése, feltöltése',
      map: 'Adatok térképi megjelenítése, összehasonlítása',
      fertilizerDatabase: 'Tápanyagok felvitele, szerkesztése',
      substanceDatabases: 'Kijuttatható anyagok adatbázisai',
      applicationPlans: 'Tervek készítése, kezelése',
      monitoring: 'Időjárási és műholdi adatok elemzése',
      settings: 'Személyes adatok, jelszó módosítása'
    }
  },
  parcel: {
    offerTitle: {
      hectare: 'ha'
    },
    tab: {
      offers: 'Gazdaságok',
      groups: 'Táblacsoportok'
    },
    btn: {
      newGroup: 'Új csoport',
      uploadFiles: 'Fáljok feltöltése'
    },
    title: 'Tábla adatbázis',
    toolbar: {
      title: 'Összes terület:',
      usedArea: '~{used}ha / {available}ha felhasználva',
      areaInfo: 'Az előfizetésbe foglalt terület korlát. A feltöltött határvonal által meghatározott összevont terület alapján kerül mérésre.'
    },
    layer: {
      year: 'Év',
      name: 'Név'
    },
    dialog: {
      newParcelGroup: {
        title: 'Új csoport létrehozása',
        offers: {
          title: 'Gazdaságok'
        },
        groups: {
          title: 'Csoportok'
        },
        parcels: {
          title: 'Táblák',
          selectAll: 'Összes kiválasztása'
        },
        selectedParcels: {
          title: 'Kiválasztott táblák',
          deleteAll: 'Összes kiválasztott törlése',
          parcel: 'tábla',
          hectare: 'ha'
        },
        createGroup: 'csoport létrehozása',
        parcel: 'tábla',
        hectare: 'ha',
        groupName: 'Csoport neve',
        filter: 'Szűrés',
        groupDetails: 'Csoport adatai',
        parcelName: 'Tábla neve',
        currentCrop: 'Aktuális növény',
        createSuccess: 'Sikeres létrehozás',
        createError: 'Sikertelen létrehozás',
        notUniqueGroupName: 'Ez a csoportnév már foglalt!',
        area: 'Terület',
        farmName: 'Gazdaság'
      },
      deleteLayer: {
        title: 'Tábla adat törlése',
        text: 'Biztos, hogy törli a(z) "<strong>{name}</strong> ({year})" megnevezésű adatot?'
      },
      editLayer: {
        title: 'Tábla adat módosítása'
      },
      editParcelGroup: {
        title: 'Csoport módosítása',
        editSuccess: 'Sikeres módosítás',
        editError: 'Sikertelen módosítás',
        editGroup: 'Csoport módosítása'
      },
      removeParcelGroup: {
        title: 'Tábla törlése',
        text: 'Biztos szeretné törölni a táblát?'
      }
    },
    validation: {
      missingParcelsFromGroup: 'Kiválasztott tábla nélkül nem hozható létre a csoport!'
    },
    notifications: {
      editLayer: {
        success: 'Adatok módosítása sikeres megtörtént!',
        error: 'Hiba az adatok módosítása során!'
      },
      removeLayer: {
        success: 'Réteg sikeresen törölve!',
        error: 'Hiba a réteg törlése során!'
      }
    },
    label: {
      zones: 'Zónák',
      works: 'Munkák',
      demoOffer: 'Minta gazdaság'
    }
  },
  fertilizer: {
    label: {
      create: 'Műtrágya létrehozása',
      name: 'Név',
      nitrogen: 'Nitrogén (N)',
      phosphor: 'Foszfor (P²O⁵)',
      potassium: 'Kálium (K²O)',
      state: 'Halmazállapot',
      solid: 'Szilárd',
      liquid: 'Folyékony',
      complex: 'Komplex',
      mono: 'Mono',
      own: 'Saját',
      maximap: 'MaxiMap',
      composits: 'Összetevők',
      composition: 'Összetétel',
      creator: 'Létrehozó'
    },
    state: {
      solid: 'Szilárd',
      liquid: 'Folyékony'
    },
    btn: {
      addNew: 'Új műtrágya'
    },
    dialog: {
      delete: {
        title: 'Törlés jóváhagyása',
        text: 'Biztosan törli a műtrágyát?'
      }
    },
    validator: {
      composition: {
        invalidMessage: 'Az összetevők százalákos arányainak összege 1 és 100 közé kell hogy essen.'
      },
      name: {
        invalidMessage: 'A megadott név foglalt!'
      }
    },
    notification: {
      deleteSuccess: 'Műtrágya sikeresen törölve!',
      deleteError: 'Műtrágya törlése során hiba lépett fel!',
      createSuccess: 'Műtrágya sikeresen mentve!',
      createError: 'Műtrágya mentése során hiba lépett fel!'
    }
  },
  userProfile: {
    area: {
      limit: 'Limit',
      uploaded: 'Feltöltve',
      unused: 'Kihasználatlan'
    },
    confirm: {
      passwordChange: {
        title: 'Jelszó megváltoztatása',
        text: 'Biztosan megváltoztatja a jelszavát? A Vantage rendszerben is az új jelszóval fog tudni belépni a változtatás után!'
      }
    }
  },
  geoLayerUpload: {
    mapping: {
      deliveredDose: 'Kijuttatott dózis',
      plannedDose: 'Tervezett dózis',
      speed: 'Sebesség',
      height: 'Magasság',
      mass: 'Tömeg',
      seedWetness: 'Szemnedvesség',
      clasCellYield: 'Cellahozam',
      cellYield: 'Cellahozam',
      yield: 'Cellahozam',
      deliveredStemNumber: 'Kijuttatott tőszám',
      plannedStemNumber: 'Tervezett tőszám',
      zoneStrength: 'Zónaerő',
      pH_KCL: 'pH-KCL',
      boundness: 'Kötöttség',
      allSalt: 'Összes só',
      CaCO3: 'CaCO3',
      humus: 'Humusz',
      P2O5: 'P2O5',
      K2O: 'K2O',
      NOX_N: 'NOX-N',
      Na: 'Na',
      SO42_S: 'SO42-S',
      Mn: 'Mn',
      Zn: 'Zn',
      Cu: 'Cu',
      Mg: 'Mg',
      N: 'N',
      P: 'P',
      K: 'K'
    },
    validation: {
      missingAttributeGiven: 'Legalább 1 attribútum megadása kötelező!'
    },
    infoModal: {
      title: 'Többszörös szerkesztés',
      text: 'Többszörös szerkesztés esetén csak azok a fájlból jövő attribútumok szerkeszthetőek, melyek minden fájlban benne vannak.'
    },
    dialog: {
      finalize: {
        title: 'Réteg véglegesítése',
        multipleTitle: 'Rétegek véglegesítése',
        text: 'Biztos, hogy véglegesíti a(z) "<strong>{name}</strong> ({year})" megnevezésű réteget?',
        multipleText: 'Biztos, hogy véglegesíti a(z) "{layers}" megnevezésű rétegeket?'
      },
      delete: {
        title: 'Réteg törlése',
        multipleTitle: 'Rétegek törlése',
        text: 'Biztos, hogy törli a(z) "<strong>{name}</strong> ({year})" megnevezésű réteget?',
        multipleText: 'Biztos, hogy törli a(z) "{layers}" megnevezésű rétegeket?'
      }
    },
    title: 'Beolvasott fájlok',
    layer: 'réteg',
    match: 'Egyezés:',
    attributeDifferenceModal: {
      title: 'Attribútumok'
    },
    nonMatchingWarning: {
      differentAttributes: 'A fájlok különböző attribútumokat taralmaznak! ',
      matchingAttributes: 'Közös attribútumok száma: {count}'
    },
    editStatusText: {
      editable: 'Hiányzó adatok',
      finalizable: 'Véglegesíthető',
      unmatched: 'Nincs egyezés'
    },
    processStatus: {
      editable: 'Hiányzó adatok!',
      unmatched: 'Nincs egyezés!'
    },
    status: {
      unmatched: 'Nincs egyezés',
      editable: 'Hiányzó adatok',
      finalizable: 'Véglegesíthető'
    },
    layerGroupKind: {
      selfZone: 'Saját zónaelhatárolás  ',
      soilSample: 'Talajminta',
      zoneStrength: 'Zónaerő',
      yield: 'Hozam',
      cellYield: 'Cellahozam',
      fertilizing: 'Műtrágyázás',
      pesticidePlan: 'Permetezés',
      seedPlan: 'Vetés',
      clasCellYield: 'CLAAS Cellahozam'
    },
    label: {
      name: 'Réteg neve',
      year: 'Év',
      layerGroupKind: 'Réteg típusa'
    },
    attributeTableModal: {
      title: 'Attribútum tábla',
      addAttribute: 'Attribútum hozzáadása'
    },
    workYieldAttribute: {
      mass: 'Tömeg',
      speed: 'Sebesség',
      seedWetness: 'Szemnedvesség',
      height: 'Magasság'
    },
    workCellYieldAttribute: {
      cellYield: 'Cellahozam'
    },
    workClaasCellYieldAttribute: {
      clasCellYield: 'Cellahozam'
    },
    workFertilizationAttribute: {
      deliveredDose: 'Kijuttatott dózis',
      plannedDose: 'Tervezett dózis',
      speed: 'Sebesség',
      height: 'Magasság'
    },
    workSprayingAttribute: {
      deliveredDose: 'Kijuttatott dózis',
      plannedDose: 'Tervezett dózis',
      speed: 'Sebesség',
      height: 'Magasság'
    },
    workSeedingAttribute: {
      deliveredStemNumber: 'Kijuttatott tőszám',
      plannedStemNumber: 'Tervezett tőszám',
      speed: 'Sebesség',
      height: 'Magasság'
    },
    zoneStrengthAttribute: {
      zoneStrength: 'Zónaerő'
    },
    soilSampleAttribute: {
      pH_KCL: 'pH-KCL',
      boundness: 'Kötöttség',
      allSalt: 'Összes só',
      CaCO3: 'CaCO3',
      humus: 'Humusz',
      P2O5: 'P2O5',
      K2O: 'K2O',
      NOX_N: 'NOX-N',
      Na: 'Na',
      SO42_S: 'SO42-S',
      Mn: 'Mn',
      Zn: 'Zn',
      Cu: 'Cu',
      Mg: 'Mg'
    },
    expertAdviceAttribute: {
      N: 'N',
      P: 'P',
      K: 'K',
      Mg: 'Mg',
      Zn: 'Zn',
      Cu: 'Cu',
      Mn: 'Mn'
    },
    classification: {
      work: 'Munka',
      zone: 'Zóna',
      plan: 'Terv'
    },
    notification: {
      editSuccess: 'Sikeres módosítás',
      editError: 'Sikertelen módosítás',
      deleteSuccess: 'Sikeres törlés',
      deleteError: 'Sikertelen törlés',
      finalizeSuccess: 'Sikeres véglegesítés',
      finalizeError: 'Sikertelen véglegesítés',
      uploadSuccess: 'Sikeres feltöltés',
      uploadError: 'Sikertelen feltöltés'
    }
  },
  button: {
    save: 'Mentés',
    edit: 'Szerkesztés',
    delete: 'Törlés',
    cancel: 'Mégse',
    finalize: 'Véglegesítés',
    upload: 'Feltöltés',
    download: 'Letöltés',
    openAttributeTable: 'Attribútum tábla megnyitása',
    details: 'Részletek',
    next: 'Tovább',
    back: 'Vissza',
    open: 'Megnyitás',
    revert: 'Visszavonás',
    openDetails: 'Megtekintés'
  },
  filter: {
    search: 'Keresés',
    status: 'Státusz',
    type: 'Típus'
  },
  notification: {
    removeSuccess: 'Sikeres törlés',
    removeError: 'Sikertelen törlés'
  },
  plan: {
    label: {
      plan: 'Kijuttatási terv',
      plans: 'Kijuttatási tervek',
      newPlan: 'Új terv készítése',
      uploadFiles: 'Fájl feltöltés',
      downloadPlans: 'Tervek letöltése',
      noPlanSelected: 'Nincs terv kiválasztva',
      planType: 'Típus',
      dosePlan: 'Dozírozás',
      name: 'Név',
      parcelNames: 'Táblák',
      parcel: 'Tábla',
      createdAt: 'Létrehozva',
      done: 'Tervek',
      year: 'Év',
      noData: 'Nincs megjeleníthető adat',
      sumApplication: 'Tervezett össz. kijuttatás',
      realSumApplication: 'Tényleges össz. kijuttatás',
      sumView: 'Összesítve',
      zoneView: 'Zónánként',
      status: 'Státusz',
      attachedWork: 'Csatolt munka',
      unknown: 'Ismeretlen',
      zone: 'Zóna',
      area: 'Terület',
      fileType: 'Fájltípus',
      layers: 'Rétegek',
      parcels: 'Táblák',
      parcelUnion: 'Táblák csoportosítása',
      parcelUnionHint:
        'A kiválasztott táblák különböző csoportokba rendezhetők, amik egy közös fájlba kerülnek. Minden tábla, ami nincs egyik csoportban sem, külön fájlokba kerül.',
      parcelUnionName: 'Egyesített név',
      parcelUnionNameHint: 'Alapértelmezett név: "Táblák"',
      percentage: 'Százalékos bontás',
      percentageValue: 'Százalék',
      generateNewFile: 'Új fájlok generálása',
      ungroupedParcels: 'Önálló táblák'
    },
    planType: {
      fertilizerPlan: 'Műtrágya',
      pesticidePlan: 'Növényvédőszer',
      seedPlan: 'Vetőmag'
    },
    dosePlan: {
      zoneStrength: 'Zónaerők',
      selfZone: 'Saját zónalehatárolás',
      homogeneous: 'Homogén',
      advice: 'Szaktanácsadás'
    },
    fileType: {
      trimbleGfxTmx: 'Trimble GFX TMX',
      trimbleCfx: 'Trimble CFX',
      topcon: 'Topcon',
      shpFile: 'SHP'
    },
    messages: {
      planLoadError: 'Hiba történt a kijuttatási terv betöltése során',
      planDownloadError: 'Hiba történt a fájlok letöltése során',
      planFileGenerationError: 'Hiba történt a fájlok előállítása során',
      planFileGenerationSuccess: 'A fájlok elkészültek',
      planDownloadNoParcelSelected: 'Nincs tábla kiválasztva'
    },
    wizard: {
      step: {
        planType: 'Kijuttatandó anyag',
        dosePlan: 'Számolási módszer',
        baseSettings: 'Alapbeállítások',
        application: 'Kijuttatás',
        overview: 'Áttekintés'
      },
      label: {
        openParcels: 'Táblák megnyitása',
        openGroups: 'Csoport megnyitása',
        saveGroup: 'Csoport mentése',
        parcelSummary: '<strong>Összesen:</strong> {parcelCount} tábla, {size} ha',
        appliedNutrients: 'Kijuttott tápanyagok (max. {maxNutrients})',
        appliedPesticides: 'Kijuttott permetszerek (max. {maxNutrients})',
        appliedNutrient: 'Kijuttatandó anyag',
        problematicTables: '{problematicParcelsCount} tábla miatt nem folytatható a tervezés!',
        addNutrient: 'Tápanyag hozzáadása',
        addPesticide: 'Permetszer hozzáadása',
        addSeed: 'Vetőmag kiválasztása',
        zoning: 'Zónázás',
        advice: 'Szaktanács',
        zones: 'Zónák',
        zone: 'Zóna',
        area: 'Terület',
        nutrient: 'Tápanyag',
        pesticide: 'Permetszer',
        nutrients: 'Kijuttatandó anyagok',
        amount: 'Mennyiség',
        nitrogen: 'Nitrogén',
        phosphorus: 'Foszfor',
        potassium: 'Kálium',
        planName: 'Terv neve',
        savePlan: 'Terv mentése',
        optimization: 'Optimalizálás',
        intensity: 'Intenzitás',
        zoneStrength: 'Zónaerők',
        zoneStr: 'Zónaerő',
        groupName: 'Csoport neve',
        seed: 'Vetőmag',
        applicationPerHa: 'Kijuttatás /ha',
        applicationAll: 'Össz. kijuttatás',
        sum: 'Összesen',
        minimum: 'Minimum',
        maximum: 'Maximum',
        reverseCalculation: 'Fordított számolás'
      },
      messages: {
        loadOffersError: 'Hiba történt a táblák betöltése során',
        openParcelsError: 'Hiba történt a kiválasztott táblák megnyitása során',
        loadLayerGroupError: 'Hiba történt a kiválasztott zónák betöltése során',
        savePlanError: 'Hiba történt a kijuttatási terv mentése során',
        savePlanSuccess: 'Sikeres mentés'
      },
      advice: {
        optimization: {
          npk: 'Teljes (NPK)',
          np: 'Foszfor / Nitrogén (NP)',
          nk: 'Kálium / Nitrogén (NK)',
          pk: 'Foszfor / Kálium (PK)'
        },
        intensity: {
          minimum: 'Minimum',
          environment: 'Környezetkímélő',
          scale: 'Mérlegszemléletű',
          maximum: 'Maximum'
        }
      }
    },
    modal: {
      saveGroupTitle: 'Csoport mentése',
      openParcelsTitle: 'Táblák megnyitása',
      downloadFileTitle: 'Terv letöltése',
      downloadFileSuccess: 'A fájlok elkészültek, kérjük kattintson a "Letöltés" gombra.',
      downloadFilePolling: 'A fájlok készítése folyamatban...',
      deleteTitle: 'Terv törlése',
      deleteText: 'Biztos, hogy törlöd a(z) <strong>"{planName}"</strong> elnevezésű kijuttatási tervet?'
    }
  },
  expertAdvice: {
    label: {
      parcelName: 'Tábla',
      date: 'Dátum',
      area: 'Terület',
      provider: 'Szolgáltató',
      crop: 'Növény',
      selectOne: 'Válasszon szaktanácsadást!',
      potassium: 'Kálium (kg/ha)',
      nitrogen: 'Nitrogén (kg/ha)',
      phosphor: 'Foszfor (kg/ha)',
      lime: 'Mész (kg/ha)',
      advicePlannedYield: 'Tervezett termés',
      year: 'Év'
    }
  },
  errorPage: {
    title: '404',
    description: 'A keresett oldal nem található',
    homeBtn: 'Vissza a főoldalra'
  },
  monitoring: {
    weatherForecast: {
      title: 'Időjárás előrejelzés'
    },
    satelliteImages: {
      title: 'Műholdképek'
    },
    monitoringType: {
      advanced: {
        title: 'Összetett',
        description: 'Több tábla adatainak összehasonlítása'
      },
      tables: {
        title: 'Táblák',
        description: 'Szolgáltatás aktivitása táblákra'
      },
      simple: {
        title: 'Egyszerű',
        description: 'Táblák statisztikai adatainak megjelenítése'
      }
    },
    notification: {
      selectParcelsError: 'Hiba történt a táblák betöltése során',
      activateParcelsError: 'Hiba történt a táblák aktiválása során'
    },
    title: 'Monitoring',
    subtitle: 'Aktív táblák',
    btn: {
      activateTables: 'Táblák aktiválása',
      compare: 'Összehasonlítás'
    },
    quickFilter: {
      oneWeek: '1 hétre visszamenőleg',
      oneMonth: '1 hónapra visszamenőleg',
      threeMonth: '3 hónapra visszamenőleg',
      halfYear: 'Fél évre visszamenőleg',
      oneYear: '1 évre visszamenőleg'
    },
    weatherType: {
      'clear sky': 'Tiszta égbolt',
      'few clouds': 'Kevés felhő (11-25%)',
      'scattered clouds': 'Elszórtan felhős (25-50%)',
      'broken clouds': 'Felszakadozot felhőzet (51–84%)',
      'shower rain': 'Zápor',
      rain: 'Eső',
      thunderstorm: 'Zivatar',
      snow: 'Hó',
      mist: 'Ködfátyol',
      'thunderstorm with light rain': 'Zivatar enyhe esővel',
      'thunderstorm with rain': 'Zivatar esővel',
      'thunderstorm with heavy rain': 'Zivatar heves esőzéssel',
      'light thunderstorm': 'Enyhe zivatar',
      'heavy thunderstorm': 'Erős zivatar',
      'ragged thunderstorm': 'Zivatar foltok',
      'thunderstorm with light drizzle': 'Zivatar enyhe szitálással',
      'thunderstorm with drizzle': 'Zivatar szitálással',
      'thunderstorm with heavy drizzle': 'Zivatar, erős szitálás',
      'light intensity drizzle': 'Kis intenzitású szitálás',
      drizzle: 'Szitálás',
      'heavy intensity drizzle': 'Erős intenzitású szitálás',
      'light intensity drizzle rain': 'Kis intenzitású szitáló eső',
      'drizzle rain': 'Szitáló eső',
      'heavy intensity drizzle rain': 'Erős intenzitású szitáló eső',
      'shower rain and drizzle': 'Zápor és szitálás',
      'heavy shower rain and drizzle': 'Heves zápor és szitálás',
      'shower drizzle': 'Zápor szitálás',
      'light rain': 'Enyhe eső',
      'moderate rain': 'Mérsékelt esőzés',
      'heavy intensity rain': 'Heves esőzés',
      'very heavy rain': 'Nagyon heves esőzés',
      'extreme rain': 'Extrém esőzés',
      'freezing rain': 'Ónos eső',
      'light intensity shower rain': 'Könnyű zápor',
      'heavy intensity shower rain': 'Heves zápor',
      'ragged shower rain': 'Zápor foltok',
      'light snow': 'Enyhe havazás',
      Snow: 'Hó',
      'Heavy snow': 'Erős havazás',
      Sleet: 'Havas eső',
      'Light shower sleet': 'Enyhe havas eső',
      'Shower sleet': 'Havas zápor',
      'Light rain and snow': 'Enyhe eső és hó',
      'Rain and snow': 'Eső és hó',
      'Light shower snow': 'Könnyű hó zápor ',
      'Shower snow': 'Hózápor',
      'Heavy shower snow': 'Erős hózápor',
      Smoke: 'Füst',
      Haze: 'Pára',
      'sand/ dust whirls': 'Homok / por örvény',
      fog: 'Köd',
      sand: 'Homok',
      dust: 'Por',
      'volcanic ash': 'Vulkanikus hamu',
      squalls: 'Viharos szél',
      tornado: 'Tornádó',
      'overcast clouds': 'Borús égbolt (85-100%)'
    },
    messages: {
      loadingError: 'Hiba történt a betöltés során.',
      imageLoadingError: 'Hiba történt a kép betöltése során',
      parcelLoadingError: 'Hiba történt a táblák betöltése során',
      satelliteImagesLoadingError: 'Hiba történt a polygon műholdkép adatainak betöltése során',
      weatherHistoryLoadingError: 'Hiba történt a polygon időjárás adatainak betöltése során',
      ndviLoadingError: 'Hiba történt a polygon NDVI adatainak betöltése során',
      soilLoadingError: 'Hiba történt a polygon talaj adatainak betöltése során',
      weatherForecastLoadingError: 'Hiba történt a polygon időjárás előrejelzés adatainak betöltése során'
    },
    noData: 'Nincs megjeleníthető adat',
    label: {
      maxClouds: 'Max. felhősség',
      clouds: 'Felhősség',
      avg: 'Átlag',
      median: 'Medián',
      min: 'Minimum',
      max: 'Maximum',
      update: 'Frissítés',
      temperature: 'Hőmérséklet',
      rain: 'Csapadék',
      groundTemperature: 'Talajhőmérséklet',
      surfaceGroundTemperature: 'Felületi talajhőm.',
      groundTemperature10cm: 'Talajhőm. 10cm mélyen',
      weather: 'Időjárás',
      moisture: 'Nedvesség',
      NDVI: 'NDVI',
      sync: 'Szinkron',
      syncTooltip: 'Minden polygon műholdképének szinkronizált váltása'
    }
  },
  map: {
    layerHintGroupMode: {
      isolated: 'Izolált',
      merged: 'Összesített'
    },
    advancedSettings: 'Haladó beállítások',
    classification: 'Osztályozás',
    layout: 'Elrendezés',
    mapSettings: 'Térkép beállítások',
    isolated: 'Izolált',
    isolatedDescription: 'Minden rétegre külön osztályozás a saját min-max értékei alapján',
    summarized: 'Összesített',
    summarizedDescription: 'Egy átfogó osztályzás az összes azonos típusú rétegre',
    layers: 'Rétegek',
    map: 'Térkép',
    automaticRotation: 'Automatikus rotáció',
    parcelsData: 'Tábla adatok',
    plans: 'Kijuttatási tervek',
    jumpToScale: 'Terjedelemre ugrás',
    subLayers: 'Alrétegek',
    opacity: 'Átlátszóság',
    legend: 'Jelmagyarázat',
    removeLayer: 'Réteg törlése',
    addedLayers: 'Hozzáadott rétegek',
    showTables: 'Táblák megjelenítése',
    addLayer: 'Réteg hozzáadása',
    layer: 'Réteg',
    parcelBorder: 'Táblahatár'
  },
  pesticide: {
    label: {
      name: 'Név',
      unitType: 'Mértékegység',
      creator: 'Létrehozó',
      own: 'Saját',
      maximap: 'mAXI-MAP'
    },
    filter: {
      searchText: 'Keresés'
    },
    btn: {
      addNew: 'Új permetszer'
    },
    details: {
      title: 'Új permetszer létrehozása'
    },
    dialog: {
      delete: {
        title: 'Permetszer törlése',
        text: 'Biztosan törli a permetszert?'
      }
    },
    notification: {
      deleteSuccess: 'Permetszer sikeresen törölve!',
      deleteError: 'Permetszer törlése során hiba lépett fel!',
      saveSuccess: 'Permetszer sikeresen mentve!',
      saveError: 'Permetszer mentése során hiba lépett fel!'
    },
    validator: {
      name: {
        invalidMessage: 'A megadott név foglalt!'
      }
    }
  },
  substanceDatabases: {
    fertilizersTab: 'Műtrágya-adatbázis',
    pesticidesTab: 'Permetszer-adatbázis',
    seedsTab: 'Vetőmag-adatbázis'
  },
  seed: {
    label: {
      name: 'Név',
      unitType: 'Mértékegység'
    }
  }
};
