import { ApiModule } from '@common/index';
import type { ColorMap } from '../models';

export class ColorMapApi extends ApiModule {
  protected endpoint = '/maximap';

  public getColorMap() {
    return this.api.get<ColorMap[]>(`${this.endpoint}/colormap`);
  }
}
