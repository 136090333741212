import type { RouteRecordRaw } from 'vue-router';
import login from '@common/lib/modules/auth/routes/routes';
import home from './modules/home/router/routes';
import userProfile from './modules/user-profile/routes/routes';
import { substanceDatabasesRoutes } from '@maximap-base/lib/modules/substance-databases';
import { parcelRoutes } from '@maximap-base/lib/modules/parcel';
import { mapRoutes } from '@maximap-base/lib/modules/map';
import { planRoutes } from '@maximap-base/lib/modules/plan';
import { geoLayersUploadRoutes } from '@maximap-base/lib/modules/geo-layers-upload';
import { monitoringRoutes } from '@maximap-base/lib/modules/monitoring';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    children: [
      {
        path: '',
        component: () => import('./components/layouts/HomeLayout.vue'),
        children: [...home, ...login.routes]
      },
      {
        path: '',
        component: () => import('./components/layouts/MainLayout.vue'),
        children: [
          ...parcelRoutes,
          ...substanceDatabasesRoutes,
          ...mapRoutes,
          ...geoLayersUploadRoutes,
          ...userProfile,
          ...planRoutes,
          ...monitoringRoutes
        ]
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: () => import('./modules/core/pages/ErrorPage.vue')
  }
];

export default routes;
