import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'monitoringTypeChooser',
    path: '/monitoring',
    component: () => import('../pages/MonitoringTypeChooserPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_MONITORING
    }
  },
  {
    name: 'monitoringSimple',
    path: '/monitoring/simple',
    component: () => import('../pages/MonitoringPage.vue'),
    props: { isAdvanced: false },
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_MONITORING
    }
  },
  {
    name: 'monitoringAdvanced',
    path: '/monitoring/advanced',
    component: () => import('../pages/MonitoringPage.vue'),
    props: { isAdvanced: true },
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_MONITORING
    }
  },
  {
    name: 'monitoringTables',
    path: '/monitoring/tables',
    component: () => import('../pages/MonitoringParcelsPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_MONITORING
    }
  }
];

export default routes;
