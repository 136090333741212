import type { FormElement } from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';

export class FilterFormChangePlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'filterFormChange';
  }

  public override onValueChange(element: FormElement<any>) {
    if (element.settings.custom.filterFormChange) {
      element.settings.custom.filterFormChange(element.value);
    }
  }
}
