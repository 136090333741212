import { FormValidator, type FormValidatorConfig, type FormValidatorSettings, isEmpty } from '@common/index';
import type { FertilizerComposition } from '../models';

export type FertilizerCompositionValidatorSettings = FormValidatorSettings;

export type FertilizerCompositionValidatorConfig = FormValidatorConfig<FertilizerCompositionValidatorSettings>;

export class FertilizerCompositionValidator extends FormValidator<FertilizerCompositionValidatorSettings> {
  constructor() {
    super();
    this._name = 'fertilizerComposition';
    this._settings = {
      invalidMessage: 'fertilizer.validator.composition.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: FertilizerComposition) {
    if (isEmpty(value)) {
      this.valid = false;
      this.addMessage(this.settings.invalidMessage);
      return;
    }

    let compositionSum = 0;

    if (value.nitrogen) {
      compositionSum += +value.nitrogen;
    }

    if (value.potassium) {
      compositionSum += +value.potassium;
    }

    if (value.phosphorus) {
      compositionSum += +value.phosphorus;
    }

    this.valid = compositionSum <= 100 && compositionSum > 0;

    if (!this.valid) {
      this.addMessage(this.settings.invalidMessage);
    }
  }
}
