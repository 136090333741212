import { ApiModule } from '@common/index';
import type { Seed } from '../models/seed.model';

export class SeedApi extends ApiModule {
  protected endpoint = '/maximap/seeds';

  public getSeeds() {
    return this.api.get<Seed[]>(`${this.endpoint}`);
  }
}
