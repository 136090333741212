import { formService } from '@common/lib/modules/form';
import { FertilizerCompositionValidator } from '../modules/fertilizer/validators/fertilizer-composition.validatior';
import { FertilizerNameValidator } from '../modules/fertilizer/validators/fertilizer-name.validatior';
import { LayerGroupKindChangePlugin } from '../modules/geo-layers-upload/plugins/layer-group-kind-change.plugin';
import { AttributesFormGroupChangePlugin } from '../modules/geo-layers-upload/plugins/attributes-form-group-change.plugin';
import { FilterFormChangePlugin } from '../modules/monitoring/plugins/filter-form-change.plugin';
import { PesticideNameValidator } from '../modules/pesticide/validators/pesticide-name.validatior';

export const registerValidators = () => {
  formService.registerValidator('fertilizerComposition', FertilizerCompositionValidator);
  formService.registerValidator('fertilizerName', FertilizerNameValidator);
  formService.registerValidator('pesticideName', PesticideNameValidator);
};

export const registerPlugins = () => {
  formService.registerPlugin('layerGroupKindChange', LayerGroupKindChangePlugin);
  formService.registerPlugin('attributesFormGroupChange', AttributesFormGroupChangePlugin);
  formService.registerPlugin('filterFormChange', FilterFormChangePlugin);
};

export const initForms = () => {
  registerValidators();
  registerPlugins();
};
