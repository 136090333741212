import { ApiModule } from '@common/index';
import type { ParcelGroupItem, SaveParcelGroup } from '../models/parcel-group.model';
import type { OfferItem } from '../models/offer-item.model';
import type { ParcelData } from '../models/parcel-details.model';
import type { LayerGroupClassification } from '../enums/layer-group-classification.enum';
import type { ParcelItem } from '../models/parcel-item.model';
import type { FeatureCollection, Polygon } from 'geojson';
import type { GeoLayerPreviewData } from '../../geo-layers-upload/models';

export class ParcelApi extends ApiModule {
  protected endpoint = '/maximap';

  public getParcelGroups() {
    return this.api.get<ParcelGroupItem[]>(`${this.endpoint}/parcel-groups`);
  }

  public getParcelGroup(id: string) {
    return this.api.get<ParcelGroupItem[]>(`${this.endpoint}/parcel-groups/${id}`);
  }

  public getOffers() {
    return this.api.get<OfferItem[]>(`${this.endpoint}/offer-groups`);
  }

  public getDemoParcels() {
    return this.api.get<ParcelItem[]>(`${this.endpoint}/demo-parcels`);
  }

  public createParcelGroup(saveParcelGroup: SaveParcelGroup) {
    return this.api.post<ParcelGroupItem, SaveParcelGroup>(`${this.endpoint}/parcel-groups`, saveParcelGroup);
  }

  public getParcelData(parcelId: string, classification: LayerGroupClassification[]) {
    return this.api.get<ParcelData>(`${this.endpoint}/layers/${parcelId}`, { params: { classification } });
  }

  public getLayer(layerId: string) {
    return this.api.get<any>(`${this.endpoint}/layer/${layerId}`);
  }

  public editLayer(layerId: string, dataToUpdate: any) {
    return this.api.patch<any, any>(`${this.endpoint}/layer-group/${layerId}`, dataToUpdate);
  }

  public removeLayer(layerId: string) {
    return this.api.delete<void>(`${this.endpoint}/layer-group/${layerId}`);
  }

  public editParcelGroup(id: string, saveParcelGroup: SaveParcelGroup) {
    return this.api.put<ParcelGroupItem, SaveParcelGroup>(`${this.endpoint}/parcel-groups/${id}`, saveParcelGroup);
  }

  public deleteParcelGroup(id: string) {
    return this.api.delete<void>(`${this.endpoint}/parcel-groups/${id}`);
  }

  public getRasterFile(groupId: string) {
    return this.api.get<any>(`${this.endpoint}/raster-file/${groupId}`, {
      responseType: 'blob'
    });
  }

  public getParcelGeometry(parcelId: string) {
    return this.api.get<FeatureCollection<Polygon, GeoLayerPreviewData>>('/parcel/geometry', { params: { parcels: [parcelId] } });
  }
}
