import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { FertilizerItem } from '../models/fertilizer.model';

export class FertilizerApi extends ApiModule {
  protected endpoint = '/maximap/fertilizer';

  public pageFertilizers(params: any) {
    return this.api.get<PaginatedResponseData<FertilizerItem>>(`${this.endpoint}`, { params: params });
  }

  public saveFertilizer(data: any) {
    return this.api.post(`${this.endpoint}`, data);
  }

  public deleteFertilizer(id: string) {
    return this.api.delete(`${this.endpoint}/${id}`);
  }

  public validateFertilizerName(name: string) {
    return this.api.get<{ valid: boolean }>(`/maximap/validate-fertilizer-name`, { params: { name } });
  }
}
