<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  width: {
    type: String,
    default: '4rem'
  },
  color: {
    type: String,
    default: 'rgba(0, 0, 0, 0.7)'
  },
  opacity: {
    type: Number,
    default: 0.5
  }
});

const style = computed(() => ({ width: props.width, fill: props.color, opacity: props.opacity }));
</script>

<template>
  <svg
    viewBox="0 0 512 512"
    stroke="1px"
    :style="style">
    <path
      d="m437.02 74.98c-48.353-48.351-112.64-74.98-181.02-74.98s-132.667 26.629-181.02 74.98c-48.351 48.353-74.98 112.64-74.98 181.02s26.629 132.667 74.98 181.02c48.353 48.351 112.64 74.98 181.02 74.98s132.667-26.629 181.02-74.98c48.351-48.353 74.98-112.64 74.98-181.02s-26.629-132.667-74.98-181.02zm-10.607 351.433c-45.519 45.519-106.039 70.587-170.413 70.587s-124.894-25.068-170.413-70.587-70.587-106.039-70.587-170.413 25.068-124.894 70.587-170.413 106.039-70.587 170.413-70.587 124.894 25.068 170.413 70.587 70.587 106.039 70.587 170.413-25.068 124.894-70.587 170.413z"></path>
    <path
      d="m421.362 124.034c-2.79-3.491-8-3.785-11.163-.622l-63.94 63.94c-2.088-3.765-4.655-7.291-7.69-10.478l-13.683-14.372 62.031-62.031c3.182-3.182 2.863-8.438-.683-11.211-37.495-29.328-82.53-44.83-130.234-44.83-116.66 0-211.57 94.91-211.57 211.57 0 47.704 15.502 92.739 44.83 130.236 2.769 3.54 8.021 3.872 11.211.683l58.896-58.897v40.086c0 1.919.184 3.794.519 5.617l-36.474 36.474c-3.152 3.151-2.88 8.364.622 11.163 37.832 30.23 83.465 46.208 131.966 46.208 116.66 0 211.57-94.91 211.57-211.57 0-48.501-15.978-94.134-46.208-131.966zm-219.743 25.747c-1.389 0-2.52-1.13-2.52-2.52v-16.886c0-1.389 1.13-2.519 2.52-2.519h108.763c1.389 0 2.52 1.13 2.52 2.519v16.886c0 1.389-1.13 2.52-2.52 2.52zm-105.637 220.413c-23.942-33.42-36.552-72.721-36.552-114.194 0-108.389 88.181-196.57 196.57-196.57 41.474 0 80.774 12.61 114.193 36.553l-42.292 42.292v-7.9c0-9.66-7.859-17.519-17.52-17.519h-108.762c-9.66 0-17.52 7.859-17.52 17.519v16.886c0 4.986 2.104 9.481 5.458 12.674l-16.128 16.939c-9.068 9.525-14.062 22.014-14.062 35.165v94.77zm78.385-2.086v-2.108h163.266v2.107c0 8.843-7.194 16.037-16.036 16.037h-131.194c-8.842.001-16.036-7.193-16.036-16.036zm81.633 84.462c-42.246 0-82.147-13.046-115.929-37.817l26.635-26.635c5.698 6.737 14.204 11.026 23.698 11.026h131.193c17.113 0 31.036-13.923 31.036-31.037v-31.955c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v14.848h-163.266c0-15.74 0-124.981 0-138.961 0-3.756.587-7.432 1.698-10.928h26.883c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-17.602l20.309-21.331h100.691l20.308 21.331h-88.648c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h97.93c.126.396.247.793.359 1.193.045.196.095.39.156.583.774 2.957 1.183 6.027 1.183 9.152v88.975c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-88.975c0-3.059-.281-6.079-.81-9.037l62.931-62.931c24.769 33.782 37.815 73.683 37.815 115.929 0 108.389-88.181 196.57-196.57 196.57z"></path>
    <path
      d="m315.271 329.072-3.698-12.677c-1.654-5.67-6.227-10.139-11.933-11.661l-2.369-.632c1.011-3.153 1.724-6.463 2.12-9.885h6.345c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-6.727c-.392-2.32-.933-4.581-1.615-6.768l4.284-1.458c6.015-2.047 10.353-7.22 11.321-13.5l2.483-16.108c.631-4.094-2.176-7.924-6.27-8.555-4.097-.629-7.924 2.176-8.555 6.27l-2.483 16.107c-.114.738-.623 1.345-1.33 1.585l-6.087 2.072c-.852-1.218-1.755-2.386-2.707-3.503 1.09-3.25 1.662-6.674 1.662-10.122 0-17.533-14.264-31.797-31.797-31.797s-31.797 14.264-31.797 31.797c0 3.448.573 6.872 1.662 10.122-1.224 1.436-2.362 2.966-3.419 4.566l-9.21-3.135c-.706-.24-1.215-.848-1.329-1.585l-2.483-16.108c-.63-4.094-4.458-6.898-8.555-6.27-4.094.631-6.901 4.461-6.27 8.555l2.486 16.108c.968 6.28 5.307 11.453 11.321 13.5l7.77 2.645c-.519 1.816-.945 3.678-1.267 5.582h-10.56c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h10.18c.357 3.082.977 6.07 1.835 8.937l-5.919 1.58c-5.706 1.523-10.279 5.992-11.933 11.662l-3.697 12.676c-1.16 3.977 1.124 8.14 5.1 9.3.701.204 1.408.302 2.104.302 3.248 0 6.241-2.126 7.197-5.402l3.697-12.676c.194-.666.731-1.191 1.401-1.37l8.606-2.297c7.66 11.161 19.637 18.366 33.084 18.366 13.098 0 24.805-6.834 32.483-17.503l5.372 1.434c.67.179 1.207.704 1.401 1.369l3.697 12.676c.956 3.275 3.949 5.402 7.197 5.402.695 0 1.402-.098 2.104-.302 3.974-1.159 6.258-5.323 5.098-9.299zm-57.354-100.633c9.121 0 16.552 7.312 16.776 16.38-5.139-2.55-10.811-3.975-16.776-3.975s-11.636 1.426-16.776 3.975c.225-9.068 7.655-16.38 16.776-16.38zm0 91.856c-14.8 0-26.84-14.457-26.84-32.226 0-16.101 10.84-32.226 26.84-32.226 15.959 0 26.84 16.06 26.84 32.226.001 17.77-12.04 32.226-26.84 32.226z"></path>
  </svg>
</template>
