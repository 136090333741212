import { FormValidator, type FormValidatorConfig, type FormValidatorSettings, isEmpty, type FormValidatorContext } from '@common/index';
import { useApi } from '../../../services';
import { setApiValidationErrorMessages } from '@common/lib/modules/form/utils/validartor.utils';

export type FertilizerNameValidatorSettings = FormValidatorSettings;

export type FertilizerNameValidatorConfig = FormValidatorConfig<FertilizerNameValidatorSettings>;

export class FertilizerNameValidator extends FormValidator<FertilizerNameValidatorSettings> {
  validatorApiService = useApi().fertilizer;
  constructor() {
    super();
    this._name = 'fertilizerName';
    this._settings = {
      invalidMessage: 'fertilizer.validator.name.invalidMessage',
      needTranslate: true
    };
  }

  public override async onValidate(value: any, context: FormValidatorContext) {
    if (isEmpty(value) || !context.element.enabled) {
      return;
    }

    try {
      await this.validatorApiService.validateFertilizerName(value);
      this.valid = true;
    } catch (error: any) {
      this.valid = false;
      setApiValidationErrorMessages(error.data.data, this);
    }
  }
}
