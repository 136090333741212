import { ApiModule, type PaginatedResponseData } from '@common/index';
import type { Plan, PlanListItem } from '../models/plan.model';
import type { DosePlan } from '../models/plan.model';
import type { PlanDetails } from '../models/plan-details.model';
import type { PlanFileCreateData } from '../models/plan-download.model';
import { PlanFileStatus } from '../enums/plan-file-status.enum';

export class PlanApi extends ApiModule {
  protected endpoint = '/maximap/plan';

  public getPlans(filters: any = {}) {
    return this.api.get<PaginatedResponseData<PlanListItem>>(`${this.endpoint}`, { params: filters });
  }

  public getPlan(id: string) {
    return this.api.get<PlanDetails>(`${this.endpoint}/${id}`);
  }

  public createPlan(plan: Plan) {
    return this.api.post<Plan, Plan>(`${this.endpoint}/create`, plan);
  }

  public deletePlan(id: string) {
    return this.api.delete(`${this.endpoint}/${id}`);
  }

  public getPlanWizardList(filters: any = {}) {
    return this.api.get<any>(`${this.endpoint}/wizard`, { params: filters });
  }

  public getPlanWizardDetails(id: string, dosePlanType: DosePlan) {
    return this.api.get<any>(`${this.endpoint}/wizard/${id}`, { params: { dosePlanType } });
  }

  public createPlanFile(id: string, data: PlanFileCreateData) {
    return this.api.post<any, PlanFileCreateData>(`${this.endpoint}/${id}/file`, data);
  }

  public getPlanFileStatus(id: string) {
    return this.api.get<{ status: PlanFileStatus; files: { id: string; type: string }[] }>(`${this.endpoint}/${id}/file-status`);
  }

  public downloadPlanFile(id: string, fileId: string) {
    return this.api.get<Blob>(`${this.endpoint}/${id}/file/${fileId}`, {
      responseType: 'blob'
    });
  }
}
