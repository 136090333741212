import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'parcel',
    path: '/parcel',
    component: () => import('../pages/ParcelPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_MAP
    }
  },
  {
    name: 'parcelDetails',
    path: '/parcel/:parcelId',
    props: true,
    component: () => import('../pages/ParcelDetailsPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_MAP
    }
  }
];

export default routes;
