export enum PlanType {
  FERTILIZER_PLAN = 'fertilizerPlan',
  PESTICIDE_PLAN = 'pesticidePlan',
  SEED_PLAN = 'seedPlan'
}

export enum DosePlan {
  ZONE_STRENGTH = 'zoneStrength',
  SELF_ZONE = 'selfZone',
  HOMOGENEOUS = 'homogeneous',
  ADVICE = 'advice'
}

export interface PlanListItem {
  id: string;
  name: string;
  planType: PlanType;
  dosePlan: DosePlan;
  parcels: PlanParcelitem[];
  createdAt: string;
}

export interface PlanParcelitem {
  name: string;
  demo: boolean;
  bbox: number[];
  layers: PlanLayerItem[];
}

export interface PlanLayerItem {
  id: string;
  name: string;
}

export interface Plan {
  id?: string;
  name?: string;
  planType?: PlanType;
  dosePlan?: DosePlan;
  parcels?: PlanParcel[];
}

export interface PlanParcel {
  id: string;
  layerGroup?: string;
  zones: PlanParcelZone[];
}

export interface PlanParcelZone {
  id: string;
  values: PlanParcelZoneValue[];
}

export interface PlanParcelZoneValue {
  id: string;
  value: number;
}

export interface PlanWizardListProps {
  name: string;
  area: number;
  svg?: string;
  availableTypes: { [key in DosePlan]: boolean };
  layerGroups: PlanWizardLayerGroup[];
}

export interface PlanWizardLayerGroup {
  id: string;
  name: string;
  layerGroupType: string;
  year: number;
}

export interface PlanWizardDetailsProps {
  value: number;
  area: number;
}
