import { ApiModule } from '@common/index';
import type { MonitoringParcelItem } from '../models/monitoring-parcel-item.model';
import type {
  NdviHistoryItem,
  SatelliteImagesItem,
  SoilHistoryItem,
  WeatherForecastItem,
  WeatherHistoryItem
} from '../models/simple-monitoring.model';
import axios from 'axios';

export class MonitoringApi extends ApiModule {
  protected endpoint = '/maximap/monitoring';

  public getActiveParcels() {
    return this.api.get<MonitoringParcelItem[]>(`${this.endpoint}`);
  }

  public activateParcel(idList: string[]) {
    return this.api.post<MonitoringParcelItem, { parcels: string[] }>(`${this.endpoint}`, { parcels: idList });
  }

  public inactivateParcel(id: string) {
    return this.api.post<any, any>(`${this.endpoint}/unregister`, { parcels: [id] });
  }

  public getWeatherHistory(polygonId: string, startTime: number, endTime: number) {
    return this.api.get<WeatherHistoryItem[]>(`${this.endpoint}/agro/1.0/weather/history?polyid=${polygonId}&end=${endTime}&start=${startTime}`);
  }

  public getNdviHistory(polygonId: string, startTime: number, endTime: number) {
    return this.api.get<NdviHistoryItem[]>(`${this.endpoint}/agro/1.0/ndvi/history?polyid=${polygonId}&end=${endTime}&start=${startTime}`);
  }

  public getSoilHistory(polygonId: string, startTime: number, endTime: number) {
    return this.api.get<SoilHistoryItem[]>(`${this.endpoint}/agro/1.0/soil/history?polyid=${polygonId}&end=${endTime}&start=${startTime}`);
  }

  public getWeatherForecast(polygonId: string) {
    return this.api.get<WeatherForecastItem[]>(`${this.endpoint}/agro/1.0/weather/forecast?polyid=${polygonId}`);
  }

  public getSatelliteImages(polygonId: string, startTime: number, endTime: number) {
    return this.api.get<SatelliteImagesItem[]>(`${this.endpoint}/agro/1.0/image/search?polyid=${polygonId}&end=${endTime}&start=${startTime}`);
  }

  public getRasterFile(url: string) {
    const instance = axios.create({
      withCredentials: false
    });

    return instance.get<any>(url, {
      responseType: 'blob'
    });
  }
}
