import { ColorMapApi } from './modules/colormap/services/colormap-api.service';
import { FertilizerApi } from './modules/fertilizer/services/fertilizer-api.service';
import { GeoLayersUploadApi } from './modules/geo-layers-upload/services';
import { MonitoringApi } from './modules/monitoring/services/monitoring-api.service';
import { ParcelApi } from './modules/parcel/services/parcel-api.service';
import { PesticideApi } from './modules/pesticide/services/pesticide-api.service';
import { ExpertAdviceApi } from './modules/plan/services/expert-advice.service';
import { PlanApi } from './modules/plan/services/plan-api.service';
import { SeedApi } from './modules/seed/services/seed-api.service';

const modules = {
  fertilizer: new FertilizerApi(),
  pesticide: new PesticideApi(),
  plan: new PlanApi(),
  parcel: new ParcelApi(),
  geoLayersUpload: new GeoLayersUploadApi(),
  expertAdvice: new ExpertAdviceApi(),
  colorMap: new ColorMapApi(),
  seed: new SeedApi(),
  monitoring: new MonitoringApi()
};

export default modules;
