<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  width: {
    type: String,
    default: '4rem'
  },
  color: {
    type: String,
    default: 'rgba(0, 0, 0, 0.7)'
  },
  opacity: {
    type: Number,
    default: 0.5
  }
});

const style = computed(() => ({ width: props.width, fill: props.color, opacity: props.opacity }));
</script>

<template>
  <svg
    viewBox="0 0 512 512"
    stroke="1px"
    :style="style">
    <path
      d="M491.841,156.427c-19.471-45.946-51.936-85.013-92.786-112.637C358.217,16.166,308.893-0.007,256,0	c-35.254-0.002-68.946,7.18-99.571,20.158C110.484,39.63,71.416,72.093,43.791,112.943C16.167,153.779-0.007,203.104,0,256	c-0.002,35.255,7.181,68.948,20.159,99.573c19.471,45.946,51.937,85.013,92.786,112.637C153.783,495.834,203.107,512.007,256,512	c35.253,0.002,68.946-7.18,99.571-20.158c45.945-19.471,85.013-51.935,112.638-92.785C495.834,358.22,512.007,308.894,512,256	C512.002,220.744,504.819,187.052,491.841,156.427z M460.413,342.257c-16.851,39.781-45.045,73.723-80.476,97.676	c-35.443,23.953-78.02,37.926-123.936,37.933c-30.619-0.002-59.729-6.218-86.255-17.454	c-39.781-16.851-73.724-45.044-97.677-80.475C48.114,344.495,34.14,301.917,34.133,256c0.002-30.62,6.219-59.731,17.454-86.257	c16.851-39.781,45.045-73.723,80.476-97.676C167.506,48.113,210.084,34.14,256,34.133c30.619,0.002,59.729,6.218,86.255,17.454	c39.781,16.85,73.724,45.044,97.677,80.475c23.953,35.443,37.927,78.02,37.934,123.939	C477.864,286.62,471.648,315.731,460.413,342.257z"></path>
    <path
      d="M389.594,283.832H122.406c-9.222,0-16.699,7.477-16.699,16.699s7.477,16.699,16.699,16.699h267.189	c9.222,0,16.699-7.477,16.699-16.699S398.817,283.832,389.594,283.832z"></path>
    <path
      d="M389.594,183.636H122.406c-9.222,0-16.699,7.477-16.699,16.699c0,9.222,7.477,16.699,16.699,16.699h267.189	c9.222,0,16.699-7.477,16.699-16.699C406.294,191.113,398.817,183.636,389.594,183.636z"></path>
  </svg>
</template>
