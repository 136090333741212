import { type RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'substances',
    path: '/substances',
    component: () => import('../pages/SubstanceDatabasesPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_PLAN
    },
    children: [
      {
        name: 'fertilizersTab',
        path: 'fertilizers',
        component: () => import('../../fertilizer/pages/FertilizerListPage.vue'),
        meta: {
          requiresAuth: true,
          permission: Permission.MODULE_PLAN
        }
      },
      {
        name: 'pesticidesTab',
        path: 'pesticides',
        component: () => import('../../pesticide/pages/PesticideListPage.vue'),
        meta: {
          requiresAuth: true,
          permission: Permission.MODULE_PLAN
        }
      },
      {
        name: 'seedsTab',
        path: 'seeds',
        component: () => import('../../seed/pages/SeedListPage.vue'),
        meta: {
          requiresAuth: true,
          permission: Permission.MODULE_PLAN
        }
      }
    ]
  }
];

export default routes;
