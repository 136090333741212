import { ApiModule, type PaginatedResponseData } from '@common/index';
import { type GeoLayerItem } from '../models/geo-layer-items.model';
import { type GeometryList } from '../models/geometry-list.model';
import type { GeoLayerPreviewData, GeoLayerUpdateData } from '../models';
import type { FeatureCollection, Polygon } from 'geojson';

export class GeoLayersUploadApi extends ApiModule {
  protected endpoint = '/maximap/processing';

  public upload(file: File) {
    const form = new FormData();
    form.append('file', file);

    return this.api.post<GeoLayerItem, FormData>(`${this.endpoint}/upload`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  public pageGeoLayers(params: any) {
    return this.api.get<PaginatedResponseData<GeoLayerItem[]>>(`${this.endpoint}/unprocessed`, { params: params });
  }

  public deleteGeoLayers(idList: string[]) {
    return this.api.delete<void>(`${this.endpoint}`, { params: { id: idList } });
  }

  public finalizeGeoLayers(idList: string[]) {
    return this.api.put<null, GeometryList>(`${this.endpoint}/finalize`, { geometries: idList });
  }

  public saveGeoLayers(geoLayersData: GeoLayerUpdateData[]) {
    return this.api.put<GeoLayerUpdateData[], GeoLayerUpdateData[]>(`${this.endpoint}/save`, geoLayersData);
  }

  public loadUploadPreview(uploadId: string, layerName?: string) {
    return this.api.get<FeatureCollection<Polygon, GeoLayerPreviewData>>(`${this.endpoint}/${uploadId}/preview`, { params: { layerName } });
  }

  public loadRasterFilePreview(uploadId: string) {
    return this.api.get<any>(`${this.endpoint}/${uploadId}/preview-raster-file`, {
      responseType: 'blob'
    });
  }

  public loadRasterBandPreview(uploadId: string) {
    return this.api.get<{ index: number; name: string; colorMap: string; unitType: string }[]>(`${this.endpoint}/${uploadId}/preview-raster-band`);
  }
}
