import type { FormElement } from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';

export class AttributesFormGroupChangePlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'attributesFormGroupChange';
  }

  public override onValueChange(element: FormElement<any>) {
    if (element.settings.custom.attributesChange) {
      element.settings.custom.attributesChange();
    }
  }
}
