import type { RouteRecordRaw } from 'vue-router';
import { Permission } from '@common/lib/modules/auth';

const routes: RouteRecordRaw[] = [
  {
    name: 'plans',
    path: '/plans',
    component: () => import('../pages/PlansPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_PLAN
    }
  },
  {
    name: 'planDetails',
    path: '/plans/:planId',
    component: () => import('../pages/PlanDetailsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_PLAN
    }
  },
  {
    name: 'planWizard',
    path: '/plans/wizard',
    component: () => import('../pages/PlanWizardPage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_PLAN
    }
  },
  {
    name: 'expertAdvice',
    path: '/expert-advice',
    component: () => import('../pages/ExpertAdvicePage.vue'),
    meta: {
      requiresAuth: true,
      permission: Permission.MODULE_PLAN
    }
  }
];

export default routes;
