import type { FormElement } from '@common/lib/modules/form';
import { FormElementPlugin } from '@common/lib/modules/form';

export class LayerGroupKindChangePlugin extends FormElementPlugin {
  public constructor() {
    super();
    this._name = 'layerGroupKindChange';
  }

  public override onInput(element: FormElement<any>) {
    if (element.settings.custom.layerGroupKindChange) {
      element.settings.custom.layerGroupKindChange();
    }
  }
}
